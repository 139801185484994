import logo from "./assets/logo.png";
import chrome from "./assets/chrome-icon-48.png";
import laptop from "./assets/laptop_desktop.png";
import notification from "./assets/notification-transparent.png";
import step1 from "./assets/step1-notext.png";
import step2 from "./assets/step2-notext.png";
import step3 from "./assets/step3-notext.png";
import step4 from "./assets/step4-notext.png";
import howto from "./assets/howto.gif";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwUAtEZdx4iJINbQkza1CmMLllHB1RGpY",
  authDomain: "immoscoutnotifier.firebaseapp.com",
  projectId: "immoscoutnotifier",
  storageBucket: "immoscoutnotifier.appspot.com",
  messagingSenderId: "581605157000",
  appId: "1:581605157000:web:3b5739b11ade164aa9173d",
  measurementId: "G-BX0B5F0W15",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, "opened_page");

function App() {
  return (
    <div className="mx-8 md:mx-24 max-w-[1200px] lg:mx-auto lg:px-8 ">
      <header className="App-header">
        <div class="navbar bg-base-100 my-4">
          <div class="flex flex-1 justify-center md:justify-start">
            <a
              class="inline-flex justify-center items-center normal-case text-base md:text-xl"
              href="#hero"
            >
              <img
                src={logo}
                className="h-8 md:h-12"
                alt="Immoscout Refresher Logo"
              />
              <span className="text-black font-normal ml-2 ">Immonotify</span>
            </a>
          </div>
          <div class="flex-none hidden md:block">
            <ul class="menu menu-horizontal px-1">
              <li>
                <a href="#how">How Does It Work?</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
              {/* <li tabindex="0">
                <a>
                  Parent
                  <svg
                    class="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                  </svg>
                </a>
                <ul class="p-2 bg-base-100">
                  <li>
                    <a>Submenu 1</a>
                  </li>
                  <li>
                    <a>Submenu 2</a>
                  </li>
                </ul>
              </li>
              <li>
                <a>Item 3</a>
              </li> */}
            </ul>
          </div>
        </div>
      </header>
      <section className="md:h-[32rem]" id="hero">
        <div className="flex h-full flex-col md:flex-row items-center justify-center my-24">
          <div className="mb-12 md:my-0 flex flex-col justify-center items-center md:items-start text-center md:text-left w-full md:w-1/2 md:mr-4 ">
            <h1 className="text-3xl font-semibold leading-[3rem]">
              Be the first applicant on Immoscout 🏡
            </h1>
            <h2 className="mt-2">
              Receive notifications when new apartments are available. Be the
              first applicant!
            </h2>
            <a
              className="btn btn-md bg-orange-400 hover:bg-orange-500 my-4"
              href="https://chrome.google.com/webstore/detail/immonotifier-new-apartmen/nmhcccoakjecenoofdonmcdigomabjnm?utm_source=immonotify.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                logEvent(analytics, "go_to_store");
              }}
            >
              <img src={chrome} className="h-12 py-3 pr-3" alt="Chrome icon" />
              Install on Chrome
            </a>
            <p className="text-xs text-gray-400 mt-4">
              This is an extension under constant development 🤓 <br />
              If you have any questions or feedback, feel free to reach out at{" "}
              <a href="mailto:info@immonotify.com" className="underline">
                info@immonotify.com
              </a>
              <br /> Happy to help!
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <div className="relative">
              <img src={laptop} alt="laptop" />
              <div className="absolute w-52 h-48 top-6 right-5 md:top-10 md:right-10 overflow-hidden">
                <img
                  src={notification}
                  alt="notification"
                  className="w-52 fadeInRight"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how" className="text-center mb-16">
        <h1 className=" font-bold text-2xl mt-4">How does it work?</h1>
        <div className="flex justify-center mt-4 mb-16">
          <img src={howto} alt="How to use the extension" />
        </div>
        <div>
          <div className="flex flex-col items-center justify-center mt-8">
            <h3 className="text-xl font-semibold">
              1. Set an apartment search on Immoscout 🔍
            </h3>
            <p>
              Set the filters for the apartment you are looking for. <br />
              Bookmark the search page to be able to open the page later easily.
            </p>
            <img src={step1} alt="step1" className="w-full md:w-2/3 mt-4" />
          </div>
          <div className="flex flex-col items-center justify-center mt-16">
            <h3 className="text-xl font-semibold">2. Set a timer 🔃</h3>
            <p>
              Set a timer on the extension to refresh the search page on
              Immoscout.
            </p>
            <img src={step2} alt="step2" className="w-full md:w-2/3 mt-4" />
          </div>
          <div className="flex flex-col items-center justify-center mt-16">
            <h3 className="text-xl font-semibold">
              3. Start the timer and get back to work ⏰
            </h3>
            <p>Don't waste time constantly refreshing the page.</p>
            <img src={step3} alt="step3" className="w-full md:w-2/3 mt-4" />
          </div>
          <div className="flex flex-col items-center justify-center mt-16">
            <h3 className="text-xl font-semibold">
              4. Receive a notification when a new apartment listing appears! 🔔
            </h3>
            <p>Be quick! The apartment might be gone in a few minutes.</p>
            <img src={step4} alt="step4" className="w-full md:w-2/3 mt-4" />
          </div>
        </div>
      </section>
      <div class="divider"></div>

      <section id="pricing">
        <div className="flex flex-col items-center justify-center mt-16">
          <h1 className=" font-bold text-2xl mt-4">Pricing</h1>
          <div className="flex flex-col md:flex-row items-center justify-center flex-wrap mt-8">
            {/* Free Trial */}
            <div class="card w-72 md:w-96 shadow-xl text-center mx-8 bg-orange-50 my-8">
              <div class="card-body">
                <h2 class="text-center text-xl font-bold text-orange-500">
                  Free Trial
                </h2>
                <p>Free 30-day trial to see the extension in action</p>
                <p className="font-bold text-4xl my-8 text-orange-500">0€</p>
                <p className="text-orange-400">No credit card required</p>
              </div>
            </div>
            {/* Paid */}
            <div class="card w-72 md:w-96 shadow-xl text-center mx-8 bg-orange-50 my-8">
              <div class="card-body">
                <h2 class="text-center text-xl font-bold text-orange-500">
                  Monthly subscription
                </h2>
                <p>Pay monthly, cancel anytime</p>
                <p className=" text-2xl line-through text-orange-300 decoration-orange-300">
                  19.99€
                </p>
                <p className="font-bold text-4xl mb-8 text-orange-500">9.99€</p>
                <p className="text-orange-400">Early special price</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer p-10 text-orange-900">
        <div>
          <span class="footer-title">Contact</span>
          <a class="link link-hover" href="mailto:info@immonotify.com">
            info@immonotify.com
          </a>
          {/* <a class="link link-hover">Design</a>
          <a class="link link-hover">Marketing</a>
          <a class="link link-hover">Advertisement</a> */}
        </div>
        {/* <div>
          <span class="footer-title">Company</span>
          <a class="link link-hover">About us</a>
          <a class="link link-hover">Contact</a>
          <a class="link link-hover">Jobs</a>
          <a class="link link-hover">Press kit</a>
        </div>
        <div>
          <span class="footer-title">Legal</span>
          <a class="link link-hover">Terms of use</a>
          <a class="link link-hover">Privacy policy</a>
          <a class="link link-hover">Cookie policy</a>
        </div> */}
      </footer>
    </div>
  );
}

export default App;
